<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            Apollo Scooters
          </h2>
        </b-link>
        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- password -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>
            <div
              v-if="loading"
              class="d-flex justify-content-center mb-1 mt-5"
            >
              <b-spinner label="Loading..." />
            </div>
            <!-- submit button -->
            <b-button
              v-if="!loading"
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BInputGroup, BInputGroupAppend, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    // BSV
    BSpinner,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      userEmail: '',
      password: '',
      status: '',
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    userEmail() {
      this.$refs.loginForm.reset()
    },
    password() {
      this.$refs.loginForm.reset()
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.loading = true
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              if (response.data.status === 200) {
                const { data: { profile, token, permissions } } = response.data
                useJwt.setToken(token)
                useJwt.setRefreshToken(token)
                if (window.setFirebaseUser) {
                  window.setFirebaseUser(profile)
                }
                localStorage.setItem('userData', JSON.stringify(profile))
                localStorage.setItem('permissions', JSON.stringify(permissions))
                this.$router.push({ name: 'home' })
              } else {
                this.$refs.loginForm.setErrors({ email: ['Invalid Credentials'] })
              }
            })
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
              this.$refs.loginForm.setErrors({ email: ['Invalid Credentials'] })
            // eslint-disable-next-line no-return-assign
            }).finally(() => this.loading = false)
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
